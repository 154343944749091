import React, { Component } from "react"
import { Index } from "elasticlunr"
import { Link } from "gatsby"

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div className="search-wrapper">
        <div className="search-input">
          <input className="search" type="search" placeholder="Search" value={this.state.query} onChange={this.search} />
          <svg className="search-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6.49641C12 9.53243 9.537 11.9928 6.5 11.9928C3.463 11.9928 1 9.53243 1 6.49641C1 3.4604 3.463 1 6.5 1C9.537 1 12 3.4604 12 6.49641Z" stroke="#344051" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6468 15.3448L15.0007 15.698L15.7071 14.9901L15.3532 14.6369L14.6468 15.3448ZM10.8532 10.1461L10.4993 9.79289L9.79289 10.5007L10.1468 10.8539L10.8532 10.1461ZM15.3532 14.6369L10.8532 10.1461L10.1468 10.8539L14.6468 15.3448L15.3532 14.6369Z" fill="#344051"/>
          </svg>
        </div>
        {this.state.results.length > 0 &&
          <ul className="search-results">
          {this.state.results.map(page => (
            <li key={page.id}>
              <Link to={page.path}>{page.summary ? page.summary : page.name}</Link>
            </li>
          ))}
          </ul>
        }
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}