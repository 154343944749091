import React from 'react'
import groupBy from 'lodash.groupby'
import Search from "./search"
import AsideMenu from "./asidemenu"
import slugify from 'slugify'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.dropdowns = {}
  }

  render() {
    const { apis, sidebarPages, searchIndex, active } = this.props
    const links = []
    sidebarPages.filter(p => !p.node.tag).forEach(sidebarPage => (
      links.push({
        group: null,
        id: sidebarPage.node.id,
        href: `/${sidebarPage.node.slug}`,
        title: sidebarPage.node.title,
        children: []
      })
    ))
    const apiGroups = {}
    apis.forEach(group => {
      const nodeGroups = groupBy(group.node.childrenOpenApiSpecPath, a => a.tag)
      Object.keys(nodeGroups).forEach(ngroup => {
        if (apiGroups[ngroup]) {
          apiGroups[ngroup] = apiGroups[ngroup].concat(nodeGroups[ngroup])
        } else {
          apiGroups[ngroup] = nodeGroups[ngroup]
        }
      })
    })

    Object.keys(apiGroups).forEach(ngroup => {
        const parent = {
          group: "API Documentation",
          id: ngroup,
          tag: ngroup,
          href: `#${slugify(ngroup).toLowerCase()}`,
          title: ngroup,
        }
        const children = []
        sidebarPages.filter(p => p.node.tag && p.node.tag.tag.toLowerCase() === ngroup.toLowerCase()).forEach(sidebarPage => {
          children.push({
            tag: sidebarPage.node.tag.tag,
            id: sidebarPage.node.id,
            href: `/api/${slugify(ngroup).toLowerCase()}/${sidebarPage.node.slug}`,
            title: sidebarPage.node.title,
            children: []
          })
        })
        apiGroups[ngroup].filter(api => api.operationId).forEach(api => {
          children.push({
            tag: api.tag,
            id: api.id,
            href: `/api/${slugify(ngroup).toLowerCase()}/${slugify(api.id.split(".")[2]).toLowerCase()}-${api.operationId}`,
            title: api.summary || api.operationId,
            verb: api.verb.toUpperCase(),
            children: []
          })
        })
        parent.children = children
        links.push(parent)
    })
    links.sort((a, b) => {
      if(a.tag < b.tag) { return -1 }
      if(a.tag > b.tag) { return 1 }
      return 0
    })
    return (

      <div className={`sidebar${active ? ' open' : ''}`}>
        <Search searchIndex={searchIndex} />
        <AsideMenu links={links} />
      </div>
    )
  }
}

export default Sidebar