import React, { Component } from 'react'
import { Link } from 'gatsby'
import g from 'glamorous'
import groupBy from 'lodash.groupby'


const getActiveStyle = active => {
  return {
    height: "0.9em",
    width: "0.9em",
    verticalAlign: "middle",
    float: "right",
    transition: "transform 0.2s ease-out 0s",
    transform: active ? "rotateZ(180deg)" : "rotate(0)",
  }
}

export default class AsideMenuItem extends Component {
  isChild(parent, slug) {
    return (parent.children.findIndex((e) => e.id === slug) >= 0)
  }

  render() {
    const { link, active, toggleActiveNavItem } = this.props
    const sublinks = link.children

    const sublinks_groups = groupBy(sublinks, l => {
      const path = l.id.split('.')
      if (path.length >= 4) {
        const basePath = path[4].split("/")
        const [ ignore, api, version, base, ...parts ] = basePath
        return base
      }
      return l.id
    })
    const total = Object.keys(sublinks_groups).length
    let count = 0
    const isSublinkActive = this.isChild(link, active)
    return (
      <li className={`aside-menu__list-item ${active === link.id ? "aside-menu__list-item-active" : (isSublinkActive ? "aside-menu__list-item-active-parent" : "")}`}>
        {sublinks.length ? (
          <a
            href={link.href}
            className="link"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              toggleActiveNavItem(link.id)
            }}
          >
            {link.title}
            <span className="indicator">
              <g.Svg css={getActiveStyle((isSublinkActive || active === link.id))} version="1.1" viewBox="0 0 12 7" x="0" xmlns="http://www.w3.org/2000/svg" y="0"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.64645 0.646447C5.84171 0.451184 6.15829 0.451184 6.35355 0.646447L11.7071 6L11 6.70711L6 1.70711L0.999999 6.70711L0.292892 6L5.64645 0.646447Z" fill="#344051"/></g.Svg>
            </span>
          </a>
        ) : (
          <Link
            to={`${link.href}`}
            className="link"
            onClick={() => {
              toggleActiveNavItem(link.id)
            }}
          >{link.title}</Link>
        )}
        <ul
          className={`${(isSublinkActive || active === link.id) ? " sublist-menu sublist-menu-active" : ""}`}
          style={{ display: (isSublinkActive || active === link.id) ? "block" : "none" }}
        >
          {Object.keys(sublinks_groups).map(group => {
            count += 1
            return (
              <React.Fragment key={group}>
              {
                sublinks_groups[group].map((sublink) => (
                  <li key={sublink.id} className={`sublist-menu__list-item ${active === sublink.id ? "sublist-menu__list-item-active" : ""}`}>
                    <Link
                      to={sublink.href}
                      className="link"
                      onClick={() => {
                        toggleActiveNavItem(sublink.id)
                      }}
                    >{sublink.title}</Link>
                  </li>
                ))
              }
              {(count !== total) &&
                <div class="link-spacer"></div>
              }
              </React.Fragment>
            )
          })}
        </ul>
      </li>
    )
  }
}