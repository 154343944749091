import React, { Component } from 'react';
import AsideMenuItem from './asidemenuitem'
import groupBy from 'lodash.groupby'

export default class AsideMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeNavItem: false,
    };
    this.toggleActiveNavItem = this.toggleActiveNavItem.bind(this)
  }

  componentDidMount() {
    const { links } = this.props
    const activeLink = links.find(link => {
      if (link.children) {
        const activeChild = link.children.find(c => c.href === window.location.pathname)
        if (activeChild) {
          this.setState({ activeNavItem: activeChild.id })
        }
      }
      return link.href === window.location.pathname
    })
    if (activeLink) {
      this.setState({ activeNavItem: activeLink.id })
    }
  }

  // scrollSpy() {
  //   const scrollSpyOffset = 100;
  //   const selector = 'h1, h2';
  //   const headers = Array.from(document.querySelectorAll(selector));

  //   const headersOffset = headers.map((el, i) => {
  //     const slug = el.id;
  //     let nextEl;

  //     if ((headers.length - 1) !== i) {
  //       nextEl = headers[i + 1].getBoundingClientRect().top;
  //     } else {
  //       nextEl = window.innerHeight;
  //     }
  //     const offset = (nextEl + document.body.scrollTop) - generateOffset(scrollSpyOffset);

  //     return { slug, offset };
  //   });

  //   if (!headersOffset.length) {
  //     return
  //   }

  //   const headersScrollTop = headersOffset.filter(el => el.offset > 0);

  //   if (window.history.pushState && headersScrollTop) {
  //     window.history.pushState(null, null, `#${headersScrollTop[0].slug}`);
  //   }
  //   else {
  //     window.location.hash = `#${headersScrollTop[0].slug}`;
  //   }

  //   this.setState({ activeNavItem: headersScrollTop[0] });
  // }

  toggleActiveNavItem(item) {
    const { activeNavItem } = this.state
    if (activeNavItem === item) {
      this.setState({ activeNavItem: false })
    } else {
      this.setState({ activeNavItem: item })
    }
  }

  render() {
    const { links } = this.props
    const { activeNavItem } = this.state
    const groups = groupBy(links, l => l.group)
    return (
      <ul className="aside-menu">
        {Object.keys(groups).map((group) => {
          const group_links = groups[group]
          return (group && group !== 'null') ? (
            <>
              <li className="aside-menu__list-item aside-menu__group">{group}</li>
              {group_links.map(link => (
                <AsideMenuItem
                  key={link.id}
                  link={link}
                  toggleActiveNavItem={this.toggleActiveNavItem}
                  active={activeNavItem}
                />
              ))}
            </>
          ) : (
            group_links.map(link => (
              <AsideMenuItem
                key={link.id}
                link={link}
                toggleActiveNavItem={this.toggleActiveNavItem}
                active={activeNavItem}
              />
            ))
          )
        })}
      </ul>
    )
  }
}

// const easeInCubic = (t) => t * t * t

// const generateOffset = (defaultOffset) => {
//   const breakPoint = document.documentElement.offsetHeight - (window.innerHeight * 2)
//   const currentScroll = breakPoint - document.documentElement.scrollTop

//   if (currentScroll > 0) {
//     return defaultOffset
//   }

//   let additionalOffest = easeInCubic(currentScroll * -1 / 100)

//   return (defaultOffset + additionalOffest < window.innerHeight) ? defaultOffset + additionalOffest : additionalOffest
// }