/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Sidebar from "./sidebar"
import LangSelector from './langselector'
import "./style.css"
import './prism-dracula.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      options: [],
      selected: ''
    }
    this.toggleSidebar = this.toggleSidebar.bind(this)
    this.setLang = this.setLang.bind(this)
  }

  toggleSidebar() {
    this.setState({ active: !this.state.active })
  }

  setLang(lang) {
    this.setState({ selected: lang });
  }

  render() {
    const { children } = this.props
    const { active, options, selected } = this.state
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            siteSearchIndex {
              index
            }
            allStrapiPages {
              edges {
                node {
                  id
                  title
                  slug
                  tag {
                    tag
                  }
                }
              }
            }
            allOpenApiSpec {
              edges {
                node {
                  id
                  name
                  childrenOpenApiSpecPath {
                    id
                    name
                    description
                    operationId
                    summary
                    tag
                    verb
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Header
              active={active}
              toggleSidebar={this.toggleSidebar}
              siteTitle={data.site.siteMetadata.title}
            />
            <Sidebar
              active={active}
              toggleSidebar={this.toggleSidebar}
              searchIndex={data.siteSearchIndex.index}
              apis={data.allOpenApiSpec.edges}
              sidebarPages={data.allStrapiPages.edges}
            />
            <div className="container">
              <LangSelector
                options={options}
                selected={selected}
                setLang={this.setLang}
              />
              {children}
            </div>
            <div className="footer"></div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
